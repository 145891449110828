let USE_LOCAL_SERVER = false // DON'T change this.
export var BLOG_MODE = false // DON'T change this.
let USE_STAGING_SERVER = false // DON'T change this.

// for local testing, change variables here if needed
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
    USE_LOCAL_SERVER = false
    USE_STAGING_SERVER = false
    BLOG_MODE = true
}

const stagingServer = 'http://clarivorportal-dev-20231107.eba-ayavmqca.us-east-2.elasticbeanstalk.com/app/api'
let api_url = USE_STAGING_SERVER ? stagingServer : 'https://portal.clarivor.com/app/api'

if (USE_LOCAL_SERVER)
    api_url = 'http://127.0.0.1:8000/app/api'

export const API_ENDPOINT = {
    LOGOUT: api_url + '/logout',
    TOKEN: api_url + '/token',
    TOKENREFRESH: api_url + '/token/refresh',
    STRATEGY_EXECUTION: api_url + '/strategy-execution',
    ACCOUNT_HOLDINGS: api_url + '/account-holdings',
    USER_STRATEGIES: api_url + '/user-strategies',
    STRATEGY_INSTANCE: api_url + '/strategy-instance',
    USER_INDICATORS: api_url + '/user-indicators',
//    REBALANCES: api_url + '/rebalances',
//     RESET_ACCOUNT: api_url + '/reset-account',
    EXECUTE_EXCESS: api_url + '/execute-excess',
    EXECUTE_OUTSTANDING: api_url + '/execute-outstanding',
    STRATEGY_TIMESERIES_LIST: api_url + '/user-strategy-timeseries-list',
    STOCK_HISTORY_LIST: api_url + '/stock-history-list',
    INDICATOR_TIMESERIES_LIST: api_url + '/user-indicator-timeseries-list',
    USER_STRATEGY_POSITIONS: api_url + '/user-strategy-positions',
    INDICATOR_STATS_LIST: api_url + '/indicator-stats',
    ACCOUNT_HOLDINGS_HISTORIES: api_url + '/account-holdings-histories',
    ACCOUNT_NAV_HISTORY: api_url + '/account-nav-history',
    ACCOUNT_STRATEGY_ALLOCATIONS: api_url + '/account-strategy-basket_allocator',
    STRATEGY_STATS_LIST: api_url + '/strategy-stats',
    USER_INVESTMENT_ACCOUNTS: api_url + '/user-investment-accounts',
    INVESTMENT_ACCOUNT: api_url + '/investment-account',
    ACCEPT_TERMS: api_url + '/accept-terms',
    CONTACT_FORM: api_url + '/contact-form',
    UPDATE_EQUITY_PORTFOLIO_WEIGHTS: api_url + '/update-equity-portfolio-weights',
    ASSET_PERFORMANCE: api_url + '/asset-performance',
    INVESTMENT_APP_CONFIG: api_url + '/investment-app-config',
    INVITE_SIGNUP: api_url + '/invite-account',
    POSITION_TRANSFERS: api_url + '/position-transfers',
    CREATE_STRATEGY_INSTANCE: api_url + '/create-strategy-instance',
    DELETE_USER_STRATEGY: api_url + '/delete-user-strategy',
    HOLDINGS_STRATEGY_BASKET: api_url + '/holdings-strategy-basket',
    CREATE_EQUITY_STRATEGY: api_url + '/create-equity-strategy',
    STRATEGY_LEVELS: api_url + '/strategy-levels',
    UX_EVENT: api_url + '/ux_event',

    // trader:
    CREATE_ALLOCATION_AND_TRADES: api_url + '/trader/create-basket_allocator-and-trades',
    CREATE_CONTRACTS_BASKET: api_url + '/trader/create-contracts-basket',
    CANCEL_RTLS: api_url + '/trader/cancel-rtls',
    TRANSFER_STOCK: api_url + '/trader/transfer-stock',
    TARGET_HOLDINGS: api_url + '/trader/target-holdings',
    IGNORE_TRADES: api_url + '/trader/ignore-trades',
    BROKER_CONNECTION_STATUS: api_url + '/trader/broker-connection-status',
    PLACE_ORDERS_FOR_PENDING_TRADE_COMBO: api_url + '/trader/place-orders-for-trade-combo',
    PLACE_ORDERS_FOR_RESOLVED_TRADES: api_url + '/trader/place-orders-for-resolved-trades',
    TRADE_EXCESS_POSITION: api_url + '/trader/trade-excess-position',
    RESET_CASH: api_url + '/trader/reset-cash',
    INTERNALIZE_PENDING_TRADE_LEG: api_url + '/trader/internalize-pending-trade-leg',
    GET_HOLDINGS_FOR_HYPOTHETICAL_BASKET_ALLOCATION: api_url + '/trader/get-holdings-for-hypothetical-basket-allocation',
    GET_HOLDINGS_FOR_HYPOTHETICAL_BASKET_ROLL: api_url + '/trader/get-holdings-for-hypothetical-basket-roll',
    RESET_PENDING_TRADES: api_url + '/trader/reset-pending-trades',
    RESOLVE_PENDING_TRADE_COMBOS: api_url + '/trader/resolve-pending-trade-combos',
    QUOTE_INSTRUMENTS: api_url + '/trader/quote-excess-instruments',
    CANCEL_ORDER: api_url + '/trader/cancel-order',
    ROLL_BASKET_ALLOCATIONS: api_url + '/trader/roll-basket-allocations',

    // order management:
    PLACE_ORDERS_FOR_AGGREGATE_RESOLVED_TRADES: api_url + '/oms/place-orders-for-agg-rtls',
    ALLOCATED_EXECUTIONS: api_url + '/oms/allocated-executions',
    AGGREGATE_ORDERS: api_url + '/oms/aggregate-account-orders',
    ORDER_LEG_ALLOCATIONS: api_url + '/oms/ol-allocations',
    PROCESS_NEW_EXECUTIONS: api_url + '/oms/process-new-executions',

    // allocator
    ACCOUNT_BASKET_TEMPLATES: api_url + '/allocator/account-basket-templates',
    SET_OVERLAY_ALLOCATIONS: api_url + '/allocator/set-overlay-allocations',

    // trading records:
    PENDING_TRADE_COMBOS: api_url + '/trading-records/pending-trade-combos',
    ORDERS: api_url + '/trading-records/orders',
    // old
    INSTRUMENT_TRADES: api_url + '/trading-records/instrument-trades',
    INSTRUMENT_ORDERS: api_url + '/trading-records/instrument-orders',
    RECENT_CONTRACT_EXECUTIONS: api_url + '/trading-records/recent-contract-executions',
    CONTRACT_EXECUTIONS: api_url + '/trading-records/contract-executions',
    DEACTIVATE_BASKET: api_url + '/trading-records/deactivate-basket',
    OPEN_ORDERS_PRICING_STATUS: api_url + '/trading-records/open-orders-pricing-status',
    EXECUTION_ALLOCATIONS: api_url + '/trading-records/execution-allocations',
    RESOLVED_TRADE_LEGS: api_url + '/trading-records/resolved-trade-legs',

    // account:
    CREATE_USER: api_url + '/account/create-user',
    CHANGE_PASSWORD: api_url + '/account/change-password',
    USER: api_url + '/account/user',
    USER_PROFILE: api_url + '/account/user-profile',
    ADVISORY_CLIENT: api_url + '/account/advisory-client',
    NON_ADVISOR_USER_INFO: api_url + '/account/non-advisory-user-info',
    ADVISORY_QUESTIONNAIRE: api_url + '/account/advisory-questionnaire',
    AGREE_TO_TERMS: api_url + '/account/agree-to-terms',
    FEES: api_url + '/account/fees',

    // model portfolio
    PREVIEW_MODEL_PORTFOLIO_HOLDINGS: api_url + '/model-portfolio/preview-holdings',
    ASSIGN_MODEL_PORTFOLIO_TO_ACCOUNTS: api_url + '/model-portfolio/assign-to-',

    // custom model portfolios
    CUSTOM_MODEL_PORTFOLIO: api_url + '/cmp/portfolio_allocations/cmp',
    CUSTOM_MODEL_PORTFOLIO_COMPONENT: api_url + '/cmp/portfolio_allocations/component',
    SET_INVESTMENT_ACCOUNT_CUSTOM_MODEL: api_url + '/cmp/portfolio_allocations/set-investment-account-custom-model',
    DEACTIVATE_CMP_COMPONENT: api_url + '/cmp/portfolio_allocations/deactivate-cmp-component',
    CMP_INVESTMENT_ACCOUNT_MAPPING: api_url + '/cmp/portfolio_allocations/cmp-ia-map',
    CMP_REBALANCE_OBJ: api_url + '/cmp/portfolio_allocations/cmp-rebalance-obj/',
    PENDING_TRADE_CALC_GROUP: api_url + '/cmp/portfolio_allocations/ptc-calc-group',

    REBALANCE_CMP: api_url + '/cmp/trading/rebalance-cmp/',
    REBALANCE_AND_CALC_PTCS: api_url + '/cmp/trading/rebalance-and-calc-ptcs',
    CALC_PENDING_TRADES_FOR_MODEL: api_url + '/cmp/trading/calc-pending-trades-for-model',
    RESOLVE_CMP_PENDING_TRADE_COMBOS: api_url + '/cmp/trading/resolve-cmp-pending-trade-combos',
    CMP_PENDING_TRADE_COMBO: api_url + '/cmp/trading/cmp-pending-trade-combo/',
    ROLL_CMP_COMPONENT: api_url + '/cmp/trading/roll-cmp-component/',

    // project:
    PROJECTS: api_url + '/projects/project-list',
    PROJECT_STRATEGY_TIMESERIES_LIST: api_url + '/projects/user-strategy-timeseries-list',
    PROJECT_INDICATOR_TIMESERIES_LIST: api_url + '/projects/user-indicator-timeseries-list',
    PROJECT_INDICATOR_STATS_LIST: api_url + '/projects/user-indicator-stats-list',
    PROJECT_STOCK_HISTORY_LIST: api_url + '/projects/stock-history-list',
    PROJECT_INDICATOR_HISTORY_DAYS: api_url + '/projects/user-indicator-history-days',

    // market-data:
    ASSETS: api_url + '/market-data/assets',
    STOCK_MID_PRICES: api_url + '/market-data/stock-mid-prices',
    NEXT_DIVIDENDS: api_url + '/market-data/next-dividends',
    OPTION_CHAINS: api_url + '/market-data/option-chains',

    // reporting:
    REPORTING: {
        REPORTS: {
            REPORT_INSTANCE: api_url + '/reporting/reports/report-instance',
            REPORT_RUN_OUTPUT: api_url + '/reporting/reports/report-run-output',
        },
        PROFIT_LOSS: {
            ACCOUNT_PL_SUMMARY: api_url + '/reporting/pl/account-pl-summary',
            ACCOUNTS_PL_TIMESERIES: api_url + '/reporting/pl/accounts-pl-timeseries',
        }
    },

    // chat:
    CHAT_MESSAGE: api_url + '/chat/message',
}

export const SPY_STRATEGY_NAME = 'SPY TR'


export const StrategyHoldings = {
    strategyID: 'strategy_id',
    positions: 'positions'
}


export const Order = {
    tradeID: 'trade',
    orderType: 'order_type',
    orderPrice: 'order_price',
    OrderType: {
        market: 'market',
        limit: 'limit',
        // stop: 3,
        // stop_limit: 4,
    },
    Status: {
        sent: 1,
        filled: 2,
        canceled: 3,
        inactive: 4,
        broker_dk: 5,
    }
}

export const TradeImp = {
    Status: {
        created: 1,
        executed: 2,
        canceled: 3,
    }
}

export const Color = {
    blue: "#2C7BE5",
    green: "#00D97E",
    purple: "#6b5eae",
    gray: "#95AAC9",
    yellow: "#F6C343",
    orange: "#fd7e14",
    grayDark: "#3B506C",
    indigo: "#727cf5",
    teal: "#02a8b5",
    cyan: "#39afd1",
    pink: "#ff679b",
    red: "#E63757",
    // white: "FFFFFF",
    darkBlue: '#1B2A4E',
    black: '#000000',
}

export const OPTION_TICKERS = ['SPY', 'EEM', 'EFA', 'FXI', 'GLD', 'HYG', 'IWM', 'QQQ', 'VXX']


export const STRATEGY_CLASS_NAMES = {
    AggregateStrategy: 'AggregateStrategy',
    OptionSpread1xNStrategy: 'OptionSpread1xNStrategy',
    CashStrategy: 'CashStrategy',
    EquityBasketStrategy: 'EquityBasketStrategy',
    SingleOptionLegStrategy: 'SingleOptionLegStrategy',
    CalendarPSCSolveForCallStrikeStrategy: 'CalendarPSCSolveForCallStrikeStrategy',
    PSCSolveForCallStrikeStrategy: 'PSCSolveForCallStrikeStrategy',
    CalendarCollarSolveForCallStrikeStrategy: 'CalendarCollarSolveForCallStrikeStrategy',
    CollarSolveForCallStrikeStrategy: 'CollarSolveForCallStrikeStrategy',
    VanillaBufferCashOverlayStrategy: 'VanillaBufferCashOverlayStrategy',
    VanillaBooster2xCashOverlayStrategy: 'VanillaBooster2xCashOverlayStrategy',
    OptionSpreadStrategy: 'OptionSpreadStrategy',
    SimpleGenericSolveForStrikeStrategy: 'SimpleGenericSolveForStrikeStrategy'

}

export const INDICATOR_CLASS_NAMES = {
    OptionPriceIndicator: 'OptionPriceIndicator',
    OptionSpread1xNSolveForShortStrike: 'OptionSpread1xNSolveForShortStrike',
    PSCCallStrikeIndicator: 'PSCCallStrikeIndicator',
    CollarCallStrikeIndicator: 'CollarCallStrikeIndicator',
    OptionBidOfferIndicator: 'OptionBidOfferIndicator',
    VanillaBufferCashOverlayCallStrike: 'VanillaBufferCashOverlayCallStrike',
    VanillaBooster2xCashOverlayCallStrike: 'VanillaBooster2xCashOverlayCallStrike',
    FixedPremiumOptionStrike: 'FixedPremiumOptionStrike'
}

export const USER_IDS = {
    clarivor_app: 1
}


export const CLIENT_TYPES = {
    ADVISER: 'adviser',
    INSTITUTION: 'institution',
    INDIVIDUAL: 'individual'
}


export const STATES = [
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["American Samoa", "AS"],
    ["Arizona", "AZ"],
    ["Arkansas", "AR"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["District of Columbia", "DC"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Guam", "GU"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Northern Mariana Is", "MP"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Puerto Rico", "PR"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Virgin Islands", "VI"],
    ["Washington", "WA"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"],
]


export const PRODUCT_IDS = {
    basicTool: 'basicTool'
}


export const IVOL_TICKERS = ['SPY', 'EFA', 'EEM', 'QQQ', 'IWM', 'GLD', 'HYG', 'FXI', 'TLT', 'VXX', 'SPX', 'VIX', 'XSP',
    'XLC', 'XLY', 'XLP', 'XLE', 'XLF', 'XLV', 'XLI', 'XLB', 'XLRE', 'XLK', 'XLU', 'KBE', 'IYR', 'OIH',
    'XHB', 'LQD', 'IEF',

    'AAPL', 'MSFT', 'AMZN', 'GOOGL', 'GOOG', 'META', 'TSLA', 'NVDA', 'BRK/B', 'JNJ', 'UNH', 'JPM', 'HD',
    'PG',
    'V', 'MA', 'BAC', 'XOM', 'PFE', 'DIS', 'AMD', 'WFC', 'COP', 'MCD', 'KO', 'WMT', 'CVX', 'ABBV', 'BLK',
    'MSCI',

    "SWKS", "SBLK", "NKE", "MU", "MRK", "MKC", "LNG", "INTC", "IBM", "HOLX", "DVN", "DUK", "CVS", "CEQP",
    "CC", "BP", "BLDR", "ATVI", "ATKR", "ACGL",

    "ICLN", "KRE", "LIT",  // ETFs
    "IEP", "EOG", "WPC", "OXY", "DBA", "UUP", "RIVN", "CSCO", "TFC", "VZ",

    "CCOI", "AXSM", "LTH", "ESTE", "TXN", "PGR", "SCHW", "HLT", "MAR", "CTRE", "BAR", "LMT",

    'LLY', 'AVGO', 'ATO', 'ADBE', 'COST', 'PEP', 'ADP', 'ACN', 'QCOM', 'LULU',

    'POOL', 'LEN', 'BWA', 'BBY', 'EBAY', 'ORLY', 'HSY', 'HRL', 'TGT', 'PXD', 'NDAQ', 'FIS', 'DFS', 'AMP', 'AXP', 'DHR',
    'BMY', 'REGN', 'WST', 'AMGN', 'PAYX', 'PH', 'EXPD', 'LHX', 'UPS', 'AMAT', 'LRCX', 'MCHP', 'KLAC', 'CTSH', 'ORCL',
    'NUE', 'STLD', 'CF', 'WBD', 'MTCH', 'EA', 'AMT',

    'CRM', 'NFLX', 'TMO', 'LIN', 'ABT', 'CMCSA', 'INTU', 'CAT', 'UNP', 'SPGI', 'NOW', 'GE', 'HON', 'NEE',

    'PM', 'LOW', 'GS', 'UBER', 'RTX', 'PLD', 'BKNG', 'ISRG', 'MDT', 'ELV', 'VRTX', 'GILD', 'TJX', 'SBUX', 'DE', 'SYK',
    'MDLZ', 'ETN', 'ADI', 'MMC', 'PANW', 'CB', 'ZTS',

    'GD',
]
