// This must be the first line in src/index.js
import 'react-app-polyfill/ie9'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as Sentry from '@sentry/browser'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter, Router, Route} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

if (window.location.hostname.toLowerCase() !== 'localhost') {
    Sentry.init({dsn: "https://dd4a2e03935849c6b8aedf1ff82f9297@sentry.io/1833996"})
}

const queryClient = new QueryClient();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <Route component={App}/>
        </BrowserRouter>
    </QueryClientProvider>
    ,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
